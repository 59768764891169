<template>
  <div class="service-group-container">
    <div class="row mt-5">
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> speed_camera </span
          >Video Surveillance Solutions​
        </h5>
        <router-link to="#" class="hilight-font"
          >Enhance security and monitor your premises with our advanced video
          surveillance systems, providing real-time monitoring and reliable
          protection.​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> cable </span>Structure
          Cabling​​​
        </h5>
        <router-link to="#" class="hilight-font"
          >Optimize your network with our professional structured cabling
          solutions, providing reliable and scalable connectivity for your
          infrastructure.​ ​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> pallet </span>Datacenter
          Facilities​​​​
        </h5>
        <router-link to="#" class="hilight-font"
          >Ensure uninterrupted operations with our top-tier datacenter
          facilities, designed for reliability, security, and
          scalability.​​​​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> contact_phone </span
          >Collaboration Solutions​​​​​
        </h5>
        <router-link to="#" class="hilight-font"
          >Enhance teamwork and communication with our innovative collaboration
          solutions, designed to streamline workflows and boost
          productivity.​​​​​</router-link
        >
      </div>
    </div>
    <div class="readmore">
      <router-link to="#" class="d-flex align-items-center gap-2">
        <span>Read More Solution</span>
        <span class="material-symbols-outlined mt-1 ms-1" style="color: #ec6316"
          >arrow_right_alt</span
        >
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>
