<template>
  <div class="solution-container">
    <div class="row mt-5">
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> api </span>
          ERP​
        </h5>
        <router-link to="#" class="hilight-font"
          >Streamline your operations with our robust ERP development services,
          integrating all your business processes into a unified
          system.​​​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> cable </span>Multi
          Cloud​
        </h5>
        <router-link to="#" class="hilight-font"
          >Utilize to leverage the strengths of multiple cloud service
          providers, ensuring enhanced performance, flexibility, and resilience
          for your business operations.​​​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> hub</span>SD-WAN​
        </h5>
        <router-link to="#" class="hilight-font"
          >Optimize your network performance and enhance security with SD-WAN to
          ensure seamless connectivity and superior user experiences.​
          ​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> lan</span>Networking
        </h5>
        <router-link to="#" class="hilight-font"
          >Providing comprehensive network solutions to enhance connectivity,
          performance, and security for your business.​​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> analytics </span>Data
          Analytics​
        </h5>
        <router-link to="#" class="hilight-font"
          >Automate the deployment, management, and coordination of your cloud
          resources, ensuring seamless integration and efficient workflows with
          our experts.​​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3">
            shopping_cart_checkout</span
          >E-Commerce​
        </h5>
        <router-link to="#" class="hilight-font"
          >Boost your online sales with our expert e-commerce web development
          services, creating seamless. Secure and faster shopping
          experiences.​</router-link
        >
      </div>
    </div>
    <div class="readmore">
      <router-link to="#" class="d-flex align-items-center gap-2">
        <span>Read More Solution</span>
        <span class="material-symbols-outlined mt-1 ms-1" style="color: #ec6316"
          >arrow_right_alt</span
        >
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>
