<template>
  <div class="product-container">
    <div class="row mt-5">
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> desktop_landscape </span
          >Rack Type Server​
        </h5>
        <router-link to="#" class="hilight-font"
          >Boost your infrastructure with our reliable and scalable rack-type
          servers, designed to meet your enterprise needs.​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> folder_open </span
          >Network Attached Storage​
        </h5>
        <router-link to="#" class="hilight-font"
          >Share and manage your data securely with our scalable & reliable NAS
          storage solutions, ideal for businesses of all sizes.​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3">
            desktop_landscape_add </span
          >SAN Storage​
        </h5>
        <router-link to="#" class="hilight-font"
          >Enhance your enterprise data management with our robust and
          high-speed SAN storage servers, designed for optimal performance and
          scalability.​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> desktop_portrait </span
          >Tower Type Server​
        </h5>
        <router-link to="#" class="hilight-font"
          >Upgrade your office infrastructure with our powerful and reliable
          tower-type servers, perfect for small to medium-sized
          businesses.​​​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> export_notes </span
          >Hyperconverged Infrastructure​
        </h5>
        <router-link to="#" class="hilight-font"
          >Transform your IT infrastructure with our cutting-edge HCI solutions,
          offering integrated computing, storage, and
          networking.​​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> switch_access </span
          >Storage Accessories​
        </h5>
        <router-link to="#" class="hilight-font"
          >Enhance your storage solutions with our premium range of storage
          accessories, designed for superior performance and
          reliability.​​​</router-link
        >
      </div>
    </div>
    <div class="readmore">
      <router-link to="#" class="d-flex align-items-center gap-2">
        <span>Read More Solution</span>
        <span class="material-symbols-outlined mt-1 ms-1" style="color: #ec6316"
          >arrow_right_alt</span
        >
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>
