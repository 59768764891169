<template>
  <div class="product-container">
    <div class="row mt-5">
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> captive_portal </span
          >Dynamic Website​​​
        </h5>
        <router-link to="#" class="hilight-font"
          >Engage your audience with our dynamic website development services,
          delivering interactive and responsive online
          experiences.​​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3">
            shopping_cart_checkout </span
          >E-Commerce​
        </h5>
        <router-link to="#" class="hilight-font"
          >Boost your online sales with our expert e-commerce web development
          services, creating seamless. Secure and faster shopping
          experiences.​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> Wysiwyg​ </span>LMS​
        </h5>
        <router-link to="#" class="hilight-font"
          >Enhance education delivery with our customized Learning Management
          System development, providing seamless and effective e-learning
          experiences.​​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> install_mobile </span
          >Web Apps
        </h5>
        <router-link to="#" class="hilight-font"
          >Transform your digital business with our innovative web app
          development services, delivering powerful and user-friendly
          solutions.​​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> api </span>ERP
        </h5>
        <router-link to="#" class="hilight-font"
          >Streamline your operations with our robust ERP development services,
          integrating all your business processes into a unified
          system.​​​</router-link
        >
      </div>
    </div>
    <div class="readmore">
      <router-link to="#" class="d-flex align-items-center gap-2">
        <span>Read More Solution</span>
        <span class="material-symbols-outlined mt-1 ms-1" style="color: #ec6316"
          >arrow_right_alt</span
        >
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>
