<template>
    <div class="solution-container">
       
    </div>
</template>

<script>
    export default {
        
    }
</script>



