<template>
    <div class="dropdown">
        <div class="content d-flex gap-5">
            <ul class="pt-5 ps-5">
              <li>
                DevOps​
              </li>
              <li>
                Cloud​
              </li>
              <li>
                Web Development​
              </li>
              <li>
                Project Management​
              </li>
              <li>
                Cyber Security​
              </li>
              <li>
                Networking​
              </li>
              <li>
                System Administrator​
              </li>
              <li>
                Software Engineering​
              </li>
              <li>
                IoT & Automation​
              </li>
              <li>
                Security & Access Control​
              </li>
            </ul>
        </div>
      </div>
</template>

<script>
    export default {
        
    }
</script>


