<template>
  <ContactUs></ContactUs>
</template>

<script>
import ContactUs from "../components/ContactUs";
export default {
  components: { ContactUs },
};
</script>

<style></style>
