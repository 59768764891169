<template>
  <div class="solution-container">
    <div class="row mt-5">
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> foggy </span>Hybrid
          Cloud​
        </h5>
        <router-link to="#" class="hilight-font"
          >Seamlessly integrate public and private clouds to achieve secure
          cloud infrastructure, zero downtime and prevent data
          loss.​​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> cloud_done </span>Multi
          Cloud​
        </h5>
        <router-link to="#" class="hilight-font"
          >Utilize to leverage the strengths of multiple cloud service
          providers, ensuring enhanced performance, flexibility, and resilience
          for your business operations.​ ​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> hub </span>Elastic
          SD-WAN​
        </h5>
        <router-link to="#" class="hilight-font"
          >Optimize your network performance and enhance security with SD-WAN to
          ensure seamless connectivity and superior user
          experiences.​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> security </span>Cyber
          Security​
        </h5>
        <router-link to="#" class="hilight-font"
          >Provide advanced and comprehensive security solutions designed to
          protect your business from ever-evolving cyber threats or
          attack.​​</router-link
        >
      </div>

      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> automation </span
          >Orchestration​
        </h5>
        <router-link to="#" class="hilight-font"
          >Automate the deployment, management, and coordination of your cloud
          resources, ensuring seamless integration and efficient workflows with
          our experts.​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> domain </span
          >Datacenter​
        </h5>
        <router-link to="#" class="hilight-font"
          >Whether you need to enhance your existing datacenter or build a new
          one, our expert team is dedicated to provide datacenter solutions. ​
          ​</router-link
        >
      </div>
    </div>
    <div class="readmore">
      <router-link to="#" class="d-flex align-items-center gap-2">
        <span>Read More Solution</span>
        <span class="material-symbols-outlined mt-1 ms-1" style="color: #ec6316"
          >arrow_right_alt</span
        >
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>
