<template>
  <div class="product-container">
    <div class="row mt-5">
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> apps </span>Software​
        </h5>
        <router-link to="#" class="hilight-font"
          >Streamline your IT operations with our comprehensive Software
          Licensing services, ensuring compliance, cost-efficiency, and seamless
          access to the tools your business needs to thrive.​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> arrow_upward </span
          >Subscription​
        </h5>
        <router-link to="#" class="hilight-font"
          >Maximize your IT efficiency with our flexible Service Subscription
          plans, offering expert support, regular updates, and proactive
          maintenance to keep your business running smoothly.​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> support_agent </span
          >Supports​
        </h5>
        <router-link to="#" class="hilight-font"
          >Secure your business with our comprehensive Support License,
          providing round-the-clock technical assistance, updates, and peace of
          mind for your IT infrastructure.​​​</router-link
        >
      </div>
    </div>
    <div class="readmore">
      <router-link to="#" class="d-flex align-items-center gap-2">
        <span>Read More Solution</span>
        <span class="material-symbols-outlined mt-1 ms-1" style="color: #ec6316"
          >arrow_right_alt</span
        >
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>
