<template>
  <div class="product-container">
    <div class="row mt-5">
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3">
            nest_cam_iq_outdoor </span
          >Digital Camera​
        </h5>
        <router-link to="#" class="hilight-font"
          >Providing advanced & high quality digital CCTV cameras to ensure
          comprehensive surveillance and security for your
          premises.​​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> speed_camera </span>IP
          Camera​
        </h5>
        <router-link to="#" class="hilight-font"
          >Offering & delivering high-performance IP Cameras for superior
          security and surveillance to protect and monitor your
          premises.​​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3">
            background_replace </span
          >Operation Center​
        </h5>
        <router-link to="#" class="hilight-font"
          >Providing cutting-edge CCTV Monitoring and Operation Centers for
          enhanced security and efficient surveillance
          management.​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> nest_cam_iq </span
          >Accessories
        </h5>
        <router-link to="#" class="hilight-font"
          >Enterprise grade accessories for digital and IP camera to expand and
          upgrade your video surveillance system.​​​</router-link
        >
      </div>
    </div>
    <div class="readmore">
      <router-link to="#" class="d-flex align-items-center gap-2">
        <span>Read More Solution</span>
        <span class="material-symbols-outlined mt-1 ms-1" style="color: #ec6316"
          >arrow_right_alt</span
        >
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>
