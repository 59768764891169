<template>
  <div class="service-group-container">
    <div class="row mt-5">
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> construction </span>IT
          Outsource Services​​​​
        </h5>
        <router-link to="#" class="hilight-font"
          >Streamline IT operations with our comprehensive IT Outsource
          Services, providing expert support, cost-effective and enhanced
          efficiency to drive your business forward.​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> vertical_split </span
          >Managed Services​​
        </h5>
        <router-link to="#" class="hilight-font"
          >With our Managed Services, offering proactive monitoring, expert
          management, and seamless support to ensure your systems run smoothly
          and efficiently​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> devices </span>Hardware
          Delivery​​​
        </h5>
        <router-link to="#" class="hilight-font"
          >You can order various IT hardware devices such as Laptop & Desktop,
          Switch, Router, Wireless AP, Firewall, Load Balancer, Storage, Server
          and other accessories. ​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> license </span>License &
          Subscription​​
        </h5>
        <router-link to="#" class="hilight-font"
          >Unlock the full potential of your business with our comprehensive IT
          software licenses and subscription plans, designed to offer
          flexibility, the latest features, and exceptional
          value.​​​​</router-link
        >
      </div>
    </div>
    <div class="readmore">
      <router-link to="#" class="d-flex align-items-center gap-2">
        <span>Read More Solution</span>
        <span class="material-symbols-outlined mt-1 ms-1" style="color: #ec6316"
          >arrow_right_alt</span
        >
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>
