<template>
  <div class="product-container">
    <div class="row mt-5">
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> policy </span>Endpoint
          Security​
        </h5>
        <router-link to="#" class="hilight-font"
          >Delivering comprehensive endpoint security solutions to protect your
          devices and data from threats and attack.​​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> key_visualizer </span
          >Firewall​
        </h5>
        <router-link to="#" class="hilight-font"
          >Offering robust next generation firewalls to defend and protect your
          network from unauthorized access and cyber threats.​​​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3">
            screen_search_desktop </span
          >Monitoring​
        </h5>
        <router-link to="#" class="hilight-font"
          >Providing advanced IT monitoring solutions to proactively manage and
          optimize your system's performance.​​​​​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> vpn_lock </span>VPN​
        </h5>
        <router-link to="#" class="hilight-font"
          >Delivering secure VPN services to protect your data, network,
          application and ensure safe, private internet access.​​</router-link
        >
      </div>
    </div>
    <div class="readmore">
      <router-link to="#" class="d-flex align-items-center gap-2">
        <span>Read More Solution</span>
        <span class="material-symbols-outlined mt-1 ms-1" style="color: #ec6316"
          >arrow_right_alt</span
        >
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>
