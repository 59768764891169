<template>
  <div class="product-container">
    <div class="row mt-5">
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> laptop_mac </span
          >Laptop​
        </h5>
        <router-link to="#" class="hilight-font"
          >Unlock the full potential of your business with our high-performance
          Laptop tailored to meet your IT needs with unmatched reliability and
          speed.​​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> desktop_portrait </span
          >Desktop Computer​
        </h5>
        <router-link to="#" class="hilight-font"
          >Empower your business with our cutting-edge Desktop PCs, delivering
          unparalleled performance, scalability, and robust
          security.​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> desktop_windows </span
          >Monitor
        </h5>
        <router-link to="#" class="hilight-font"
          >Experience crystal-clear visuals and enhanced productivity with our
          top-tier Monitor Displays, designed to deliver seamless integration
          for your IT setup.​​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> dock </span>UPS
        </h5>
        <router-link to="#" class="hilight-font"
          >Ensure uninterrupted business ​ operations with our reliable UPS
          solutions for continuous power protection for your critical IT
          infrastructure.​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> memory_alt </span
          >Accessories​
        </h5>
        <router-link to="#" class="hilight-font"
          >Optimize your IT setup with our premium Laptop and Desktop
          Accessories, designed to enhance performance, connectivity, and user
          experience for your business.​</router-link
        >
      </div>
    </div>
    <div class="readmore">
      <router-link to="#" class="d-flex align-items-center gap-2">
        <span>Read More Solution</span>
        <span class="material-symbols-outlined mt-1 ms-1" style="color: #ec6316"
          >arrow_right_alt</span
        >
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>
