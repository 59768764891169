<template>
  <div class="product-container">
    <div class="row mt-5">
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> switch_access </span
          >Network Switch​
        </h5>
        <router-link to="#" class="hilight-font"
          >Extend your network infrastructure with our high-performance network
          switches, designed to ensure seamless connectivity and optimal data
          flow.​​​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> router </span>Router​
        </h5>
        <router-link to="#" class="hilight-font"
          >Providing top-quality network routers to enhance your business
          connectivity and performance delivering multiple network
          functions.​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> sync_desktop </span
          >LoadBalancer​
        </h5>
        <router-link to="#" class="hilight-font"
          >Supplying efficient network and application load balancers to ensure
          optimal traffic distribution and system reliability.​​​​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> bigtop_updates </span
          >Wireless Network​
        </h5>
        <router-link to="#" class="hilight-font"
          >Providing high-performance wireless devices to enhance your
          connectivity, streamline network operation and
          flexibility.​​​​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> conversion_path </span
          >Network & Fiber Cable​
        </h5>
        <router-link to="#" class="hilight-font"
          >Providing top-grade network and fiber cables for superior
          connectivity and performance.​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> lan</span>Rack &
          Accessories​
        </h5>
        <router-link to="#" class="hilight-font"
          >Offering robust network racks, server racks, and essential
          accessories to optimize your IT infrastructure.​​</router-link
        >
      </div>
    </div>
    <div class="readmore">
      <router-link to="#" class="d-flex align-items-center gap-2">
        <span>Read More Solution</span>
        <span class="material-symbols-outlined mt-1 ms-1" style="color: #ec6316"
          >arrow_right_alt</span
        >
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>
